<template>
  <div>
    <v-data-table
      dense
      :headers="cabecera"
      :items="listadoCasillero"
      :items-per-page="5"
      :custom-filter="filterOnlyCapsText"
      :search="search"
      mobile
      fixed-header
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Buscar:"
          class="col-4 mx-4"
          dense
        ></v-text-field>
      </template>
      <template v-slot:item="row">
        <tr>
          <td class="ancho">
            <v-btn icon color="warning" x-small @click="editar(row.item.lockerid)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="info" x-small @click="ver(row.item.lockerid)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn icon color="red" x-small>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>

          <td class="ancho">{{ row.item.lockercreate }}</td>
          <td class="ancho">{{ row.item.lockerexpediente }}</td>
          <td class="ancho">{{ row.item.lockerfechasalida }}</td>
          <td class="ancho">{{ row.item.lockervolumen }}</td>
          <td class="ancho">{{ row.item.lockerpeso }}</td>
          <td class="ancho">{{ row.item.lockernrobultos }}</td>
          <td class="ancho">{{ row.item.lockerganancia }}</td>
          <td class="ancho">{{ row.item.countquotes }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "listadoCasilleroComponent",
  data() {
    return {
      search: "",
      cabecera: [
        { value: "", text: "Acciones" },
        { value: "lockercreate", text: "Fecha Creación" },
        { value: "lockerexpediente", text: "Expediente", align: "center" },
        { value: "lockerfechasalida", text: "Fecha Salida" },
        { value: "lockervolumen", text: "Total Volumen (m3)" },
        { value: "lockerpeso", text: "Total Peso (kg)" },
        { value: "lockernrobultos", text: "N° Bultos (uni)" },
        { value: "lockerganancia", text: "Venta" },
        { value: "countquotes", text: "Total Cliente" },
      ],
    };
  },
  mounted() {
    this.getListadoCasillero();
  },
  computed: {
    ...mapState(["listadoCasillero"]),
  },
  methods: {
    ...mapActions(["getListadoCasillero"]),
    filterOnlyCapsText(value, search) {
      let text = search ? search.toLocaleUpperCase() : null;
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(text) !== -1
      );
    },
    ver(id) {
      this.$router.push({
        name: "ver-casillero",
        params: { id: id },
      });
    },
    editar(id) {
      this.$router.push({
        name: "editar-casillero",
        params: { id: id },
      });
    },
  },
};
</script>

<style scope lang="scss">
.ancho {
  max-width: auto !important;
}
</style>
